import breakpoints from '@client/css-modules/_breakpoints.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { LockIcon } from '@client/inline-svgs/index-for-testing-only';
import { authModalShow } from '@client/store/actions/auth.actions';
import { AUTH_MODAL_PAGES } from '@client/store/constants';
import { getSSOModalConfig } from '@client/store/selectors/cobranding.selectors';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const StyledLockedContentContainerDiv = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  max-width: 480px;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: ${breakpoints.tabletScreenMaxWidth}) {
    width: 70%;
    max-width: 70%;
    text-align: center;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const StyledButton = styled.button<{ $color?: string }>`
  color: ${(props) => props.$color};
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  font-size: 14px;
  font-weight: 800;
`;

const StyledLockIcon = styled(LockIcon)`
  margin-right: 5px;
  vertical-align: middle;

  & svg {
    height: 20px;
    width: 20px;

    & path {
      stroke-width: 4px;
    }
  }
`;

const StyledTextSpan = styled.span`
  font-weight: 700;
`;

export const LockedContent = () => {
  const { linkColor } = useCobrandStyles();
  const dispatch = useDispatch();
  const openAuthModal = () => {
    dispatch(authModalShow({ startingPage: AUTH_MODAL_PAGES.LOGIN }));
  };

  const ssoModalConfig = useSelector(getSSOModalConfig);
  if (!ssoModalConfig?.specialUserTypeSsoCtaUrl) return null;

  return (
    <StyledLockedContentContainerDiv>
      <StyledButton $color={linkColor} onClick={openAuthModal}>
        <StyledLockIcon />{' '}
        <StyledTextSpan>
          {`${ssoModalConfig?.specialUserTypeSsoCtaText} to get the Address
          Search`}
        </StyledTextSpan>
      </StyledButton>
    </StyledLockedContentContainerDiv>
  );
};
