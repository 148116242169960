import { get } from 'lodash';
import { connect } from 'react-redux';

import SearchTopBarFilters from '@client/components/SearchTopBarFilters';
import { reportEvent } from '@client/store/actions/analytics.actions';
import {
  clearCurrentSavedSearch,
  updateSavedSearch,
} from '@client/store/actions/saved-search.actions';
import {
  searchCancelPendingQueries,
  searchToggleDesktopFilters,
  searchUpdateFilter,
} from '@client/store/actions/search.actions';
import {
  SEARCH_MAP_DESKTOP_FILTERS_SHOWN_URL_KEY,
  STATUSES,
} from '@client/store/constants';
import { FILTER_KEYS } from '@client/store/filter-constants';
import { getUserBuyingPower } from '@client/store/selectors/auth.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getIsMobile,
  getIsTabletSize,
} from '@client/store/selectors/match-media.selectors';
import {
  getHasSavedCurrentSearch,
  getSavedSearchSavingStatus,
} from '@client/store/selectors/saved-search.selectors';
import {
  getFinalSearchActiveFilterCount,
  getSearchFilterValues,
} from '@client/store/selectors/search.selectors';
import { getCurrentQuery } from '@src/redux-saga-router-plus/selectors';

const mapStateToProps = (state) => {
  const hasSavedThisSearch = getHasSavedCurrentSearch(state);
  return {
    allFiltersButtonLabel: getIsTabletSize(state) ? 'Filters' : 'More Filters',
    isAllowingSaveSearch: !getIsMobile(state),
    activeFilterCount: getFinalSearchActiveFilterCount(state),
    filterValues: getSearchFilterValues(state),
    hasSavedCurrentFilter: hasSavedThisSearch,
    isDisplayMultiFamilySearchFiltersEnabled: getIsFeatureEnabled(
      'temp_display_multi_family_search_filters'
    )(state),
    isSavingCurrentFilter:
      getSavedSearchSavingStatus(state) === STATUSES.UPDATING,
    /* Passing these through due to logic tied to map in SearchPageMaps.jsx */
    userBuyingPower: getUserBuyingPower(state),
    showFiltersOnInit: get(
      getCurrentQuery(state),
      SEARCH_MAP_DESKTOP_FILTERS_SHOWN_URL_KEY,
      false
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleToggleFilters: () => dispatch(searchToggleDesktopFilters()),
  handleFilterChange: (key, value) => {
    dispatch(searchCancelPendingQueries());
    dispatch(searchUpdateFilter(key, value));
    dispatch(clearCurrentSavedSearch());

    switch (key) {
      case FILTER_KEYS.PROPERTY_TYPE: {
        dispatch(
          reportEvent('change_quick_filter_proptype', '', {
            filter_value: value,
          })
        );
        break;
      }
      case FILTER_KEYS.LIST_PRICE_MIN_MAX: {
        dispatch(
          reportEvent('change_quick_filter_list_price', '', {
            filter_value: value,
          })
        );
        break;
      }
      case FILTER_KEYS.BEDS_MIN_MAX: {
        dispatch(
          reportEvent('change_quick_filter_beds', '', {
            filter_value: value,
          })
        );
        break;
      }
    }
  },
  handleQuickFilterPropertyTypeClick: (value) =>
    dispatch(
      reportEvent('click_mobile_filter_property_type', '', {
        filter_value: value,
      })
    ),
  handleQuickFilterPriceClick: (value) =>
    dispatch(
      reportEvent('click_mobile_filter_list_price', '', {
        filter_value: value,
      })
    ),
  handleQuickFilterBedsClick: (value) =>
    dispatch(
      reportEvent('click_mobile_filter_beds', '', {
        filter_value: value,
      })
    ),
  handleUpdateSearch: () => dispatch(updateSavedSearch()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchTopBarFilters);
