import { LockedContent } from '@client/components/LockedContent/LockedContent';
import {
  LockedComponentArgs,
  useLockedComponent,
} from '@client/hooks/use-locked-component.hooks';
import { SectionId, SpecialUserType } from '@client/store/constants';
import React from 'react';

interface LockedComponentProps {
  className: string;
  children: (args: LockedComponentArgs) => JSX.Element | null;
  sectionId?: SectionId;
  lockedFor: SpecialUserType[];
}

export const LockedComponent: React.FC<LockedComponentProps> = ({
  children,
  className,
  sectionId,
  lockedFor,
}) => {
  const lockedComponentArgs = useLockedComponent({ sectionId, lockedFor });

  if (lockedComponentArgs.isLocked && sectionId) {
    return (
      <div className={className}>
        <LockedContent />
        {children(lockedComponentArgs)}
      </div>
    );
  }
  return <>{children(lockedComponentArgs)}</>;
};
