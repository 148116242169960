import {
  LOCKED_SECTION_LIST,
  SectionId,
  SpecialUserType,
} from '@client/store/constants';
import { getUserSpecialUserType } from '@client/store/selectors/auth.selectors';
import { getSSOModalConfig } from '@client/store/selectors/cobranding.selectors';
import { useSelector } from 'react-redux';

interface Props {
  sectionId?: SectionId;
  lockedFor: SpecialUserType[];
}

export type LockedComponentArgs = {
  isLocked: boolean;
};

export function useLockedComponent({ sectionId, lockedFor }: Props) {
  const ssoModalConfig = useSelector(getSSOModalConfig);
  const userSpecialType = useSelector(getUserSpecialUserType);

  const isSpecialUserTypesRestricted =
    userSpecialType === SpecialUserType.Restricted;
  const isSectionInLockedList =
    !!sectionId &&
    lockedFor.some((userType) => {
      return LOCKED_SECTION_LIST[userType].includes(sectionId);
    });
  const isLocked =
    isSpecialUserTypesRestricted &&
    isSectionInLockedList &&
    !!ssoModalConfig?.specialUserTypeSsoCtaText &&
    !!ssoModalConfig?.specialUserTypeSsoCtaUrl;

  return { isLocked };
}
